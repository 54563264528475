import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import fetchPathData from "@/lib/api/fetchPathData"
import { AppProvider } from "@/lib/contexts/appContext"
import Head from "@/lib/head"
import { Layout } from "@/components/base/layout"

export default function Error() {
  const router = useRouter()
  const [loading, setLoading] = useState(true)
  const [pathData, setPathData] = useState()

  useEffect(() => {
    if (!loading) {
      return
    }

    const fetchData = async () => {
      const pathData = await fetchPathData({ path: router.asPath })
      if (pathData.path !== undefined) {
        setPathData(pathData)
        setLoading(false)
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!loading) {
    return (
      <AppProvider pathData={pathData}>
        <Head />
        <Layout />
      </AppProvider>
    )
  }

  return null
}
